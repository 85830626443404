<template>
  <div
    ref="root"
    :class="hasMounted ? '' : 'h-0'"
    class="flex flex-col justify-start overflow-hidden"
  >
    <div ref="inner" class="w-full">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface BaseExpandingWrapperProps {
  fade?: boolean
  show?: boolean
}

const props = withDefaults(defineProps<BaseExpandingWrapperProps>(), { fade: false, show: false })
const root = ref<HTMLDivElement | null>(null)
const inner = ref<HTMLDivElement | null>(null)
const hasMounted = ref(false)

const { $anime } = useNuxtApp()

onMounted(() => {
  if (!props.show) {
    $anime.set(root.value!, { height: 0 })
  }

  hasMounted.value = true

  watch(props, ({ show }) => {
    const height = parseInt(`${$anime.get(inner.value!, 'height')}`, 10)
    const duration = Math.max(300, height * 1.25)

    const options = {
      targets: root.value!,
      height: show ? [0, height] : 0,
      opacity: {},
      translateZ: 0,
      duration,
      easing: 'cubicBezier(0.4, 0, 0.2, 1)',
      complete() {
        if (show) {
          $anime.set(root.value!, { height: 'auto' })
        }
      },
    }

    if (props.fade) {
      options.opacity = {
        value: show ? [0, 1] : 0,
        delay: show ? duration * 0.3 : 0,
      }
    }

    $anime(options)
  })
})
</script>
